import React, { useState } from "react"
import Layout, { MyLocationEnum } from '../components/Layout'
import { TransitionStatus } from "react-transition-group/Transition"


import SEO from "../components/Seo"
import ChatRoom from '../components/ChatRoom'
import ChatEmbed from '../components/Chat/ChatEmbed'
import ChatSideBar from '../components/Chat/ChatSideBar'
import { PageProps } from "gatsby"

interface HerbergiProps {
	location: PageProps
	transitionStatus: TransitionStatus,
}
export default ({
	location,
	transitionStatus
}: HerbergiProps) => {

	const [name, setNAme] = useState("");
	return (
		<Layout
			location={location}
			myLocation={MyLocationEnum.Innova}
		>
			{({ infoOpen, messageOpen, setMessageOpen }) => (
				<>
					<SEO
						title="Skráning"
						description="Fjarhöll Marel"
					/>

					{/* <ChatRoom
						enableTilt={true}
						showInfo={infoOpen}
						transitionStatus={transitionStatus}
						chatName={name}
					/> */}
				</>
			)}
		</Layout>
	)
}